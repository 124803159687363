/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 767px) {

    .after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
    .before_clear:before{clear:both;display: block;content:" "}

    .wrapper {width: auto; margin: 0 20px;}

    .btn {
        font-size: 10px; line-height: 32px;
        &:after {width: 32px; height: 32px; right: -34px; background-position: center;}
        &:hover {
            &:after {right: -40px;}
        }
    }
    .title {margin-bottom: 25px; font-size: 14px;}


    /* header
    ----------------------------------------------------------------------------------------------*/
   header {
        .wrapper {margin: 0 0 0 20px;}
        .rh_head {
            .burger_mn {width: 55px; height: 55px;}
        }
        .logo {
            img {
                width: 80px;
                &.stay {width: 80px;}
            }
        }
        &.float {
            .logo {margin-top: 10px;}
        }
    }

    .side_mnu {
        .close {width:55px; height: 55px;}
        .wrap_side {
            width: 320px; padding: 55px 55px 20px 25px;
            .bg-nav {background: none;}
            &:after {background-size: 55px !important; bottom: 128px;}
            &:before {right: 55px; background-size: 100%;}
            .nav_side {
                ul {
                    li {
                        a {font-size: 16px; margin-bottom: 6px;}
                    }
                }
            }
            .btm_side {
                padding: 20px 25px 30px;
                .btm_src {
                    form {
                        input[type="text"] {font-size: 16px;}
                        input[type="submit"] {width: 30px; background-size: 20px;}
                    }
                }
            }
        }

    }

    /* homepage
    ----------------------------------------------------------------------------------------------*/
    .slideHome {
        .slider {
            .slide {
                .caption {
                    .wrapper {width: auto;}
                    .txt {width: auto; margin-left: 25px;}
                    h1 {font-size: 20px; line-height: 26px;}
                    p {font-size: 13px; line-height: 20px;}
                }
                .images {min-height: 480px;}
            }
            .slick-dots {
                li {
                    button {width: 12px; height: 12px;}
                }
            }
        }
    }
    
    .home_business {
        background-size: 900px; background-position: top center !important;
        &:before {height: 60%; background-size: 900px;background-position: center !important;}
        .wrapper {position: relative; z-index: 2;}
    }
    .home_about {
        padding: 200px 0 70px; min-height: 0;
        .img_about {width: 320px;}
        .text_about {
            position: relative; top: 0; @include transform(translateY(0)); width: auto; max-width: 400px;
            h2 {font-size: 20px; line-height: 26px;}
            h6 {font-size: 16px; line-height: 22px;}
        }
    }
    .home_wcu {
        .list_wcu {
            .col {
                margin-right: 9%; margin-bottom: 35px;
                &:nth-of-type(3n) {margin-right: 9%;}
                &:nth-of-type(2n) {margin-right: 0;}
                .img {width: 50px;}
                .text {margin-left: 70px;}
            }
        }
        .link {
            .btn {margin-left: -30px;}
        }
    }
    .home_contact {
        min-height: 0; padding: 55px 0 45px;
        .text_contact {
            position: relative; top: 0; @include transform(translateY(0)); width: auto; max-width: 500px;
            h1 {font-size: 20px; line-height: 26px;}
            .btn {margin-top: 10px;}
        }
    }
    .scrollify.novh {min-height: 0;}
    

    /* middle
    ----------------------------------------------------------------------------------------------*/
    .middle {padding-bottom: 60px;}
    .inner_text {
        > h2 {font-size: 25px; line-height: 32px; max-width: 90%; margin-bottom: 25px;}
        .lf_txt {
            margin-bottom: 20px;
            h3 {font-size: 20px; line-height: 28px;}
        }
        .rh_txt {
            p {font-size: 14px; line-height: 26px;}
            h6 {font-size: 14px;}
            ol,ul {
                li {
                    font-size: 14px; line-height: 24px;
                    &:before {
                        top: 8px;
                    }
                }
            }
        }
        ol,ul {
            li {font-size: 14px; line-height: 24px;}
        }
        &:first-of-type {padding-top: 40px;}
    }
    
    .pg_title {
        .img_bg {height: 420px;}
        .txt_title_pg {
            width: auto; margin: 0 10%;
            h2 {font-size: 20px; margin-bottom: 15px;}
            p {font-size: 14px; line-height: 18px; max-height: 110px; overflow: hidden;}
            h3 {font-size: 28px;}
        }
        &.thank-banner {
            min-height: 465px;
            .txt_title_pg {
                h2 {font-size: 30px; line-height: 36px; margin-bottom: 15px;}
                a {margin-left: -30px;}
            }
        }
        .map {
            @include transform(scale(1)); left: 0; width: auto; height: 400px; top: -20px; margin-bottom: -40px;
            img {min-width: 420px; bottom: 0; top: 0;}
            .pointer,
            .sub-loc,
            .detail-loc-info {display: none;}
        }
    }

    .next-read {
        background-position: center; padding: 45px 0 40px;
        .wrapper {width: auto; margin: 0 20px;}
        .left,.right {float: none; display: block; width: auto;}
        .left {margin-bottom: 20px;}
        .right {
            p {max-width: 80%;}
        }
    }
    .listing {
        &.about_sub {
            .item {
                &:hover {
                    .dec {
                        .btn {line-height: 32px; height: 32px;}
                    }
                }
            }
        }
    }
    .content_service {
        padding: 50px 0; min-height: 0;
        .top_sec {width: auto;}
    }
    .service_list {
        .item {
            float: none; display: block; width: auto;
            .ico {width: 50px;}
            .txt_item {margin-left: 75px;}
        }
    }
    .address-list{
        .list{
            flex: 1 0 100%;
            max-width: 100%;
        }
    }
    
    .slider_market {margin: 30px 20px 0;}

    .content_galery {
        .slider_galery {
            width: auto; max-width: 100%; padding-top: 20px; margin: 0 25px;
            .img {
                height: auto; width: auto; overflow: hidden;
                img {position: relative;height: auto; left: 0; @include transform(translateY(0)); max-width: 100%;}
            }
        }
        .slide {
            overflow: hidden;
            .text_slide {
                padding: 0 50px 0 20px;
                h6 {font-size: 16px; padding-top: 0;}
                .btn {font-size: 0; padding: 0;}
            }
        }
        .slick-arrow {
            width: 14px; height: 25px; background-size: 14px;
            &.slick-next {right: -25px;}
            &.slick-prev {left: -25px;}
        }
    }
    .form_contact {
        margin-top: 30px;
        .row {
            margin-bottom: 25px;
            .field {
                input[type="text"],
                input[type="number"], 
                input[type="email"], 
                select, textarea {
                    font-size: 16px; padding: 10px 10px 20px;
                    + span {font-size: 16px; left: 10px;}
                }
                &.focus {
                    input[type="text"],
                    input[type="number"], 
                    input[type="email"], 
                    select, textarea {
                        + span {font-size: 12px;}
                    }
                }
            }
            .g-recaptcha {@include transform(scale(0.8)); position: relative; left: -30px;}
            &:last-of-type {margin-top: 25px;}
        }
    }

    .wrap-popup {
        .box-popup {
            width: auto; margin: 20% 30px;
            .close {
                width: 35px; height: 35px;
                &:after {width: 16px; height: 16px; background-size: 16px;}
            }
            .slider-1 {
                .text {
                    h6 {font-size: 14px;}
                }
                .slick-arrow {
                    &.slick-prev {
                        left: -25px;
                        &:hover {left: -25px;}
                    }
                    &.slick-next {
                        right: -25px;
                        &:hover {right: -25px;}
                    }
                }
            }
            .slider-2 {
                top: 100%; left: -8px; right: -8px;
                .box {width: 100px; height: 60px;}
            }
        }
    }

    /* footer
    ----------------------------------------------------------------------------------------------*/
    footer {
        padding-bottom: 75px; position: relative; background-size: 120%;
        .logo_foot {width: 80px;}
        .copyright_txt {
            position: absolute; left: 0; right: 0; margin: auto; bottom: 20px; width: 240px;
            p {font-size: 11px; line-height: 18px;}
        }
        .right_logo {
            a {display: inline-block; width: 35px;}
        }
    }


}


@media all and (max-width: 641px) {

    .after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
    .before_clear:before{clear:both;display: block;content:" "}

    .wrapper {}


    /* header
    ----------------------------------------------------------------------------------------------*/
   


    /* homepage
    ----------------------------------------------------------------------------------------------*/

    .home_business {
        background-size: 1000px; padding-bottom: 20px;
        &:before {height: 80%; background-size: 1000px;}
        .listing {
            background: none; width: auto; margin: 30px auto; max-width: 400px;
            .item {
                margin-bottom: 20px; width: auto; height: auto; padding: 35px 25px;
                h2 {font-size: 20px;}
                p {font-size: 14px; max-height: 96px;}
            }
        }
    }
    .home_about {
        padding: 220px 0 70px; min-height: 0;
        .img_about {width: 270px;}
    }
    .home_wcu {
        .list_wcu {
            .col {
                display: block; margin-right: 0; width: auto;
                &:nth-of-type(3n) {margin-right: 0;}
                &:nth-of-type(2n) {margin-right: 0;}
            }
        }
    }
    

    /* middle
    ----------------------------------------------------------------------------------------------*/
    .top_sec {
        margin: 0 20px;
    }
    .content_market {padding: 50px 0 60px;}
    .listing {
        &.about_sub {
            .item {width: 100%; float: none;}
        }
    }
    .slider_market {
        .slide {
            margin: 0 2px;
            .dec {
                p {height: 88px;}
            }
            &:hover {
                .dec {
                    @include boxShadow(none);
                    .hov {height: 170px;}
                }
            }
        }
    }
    .inner_text {
        .client-list {
            a {
                width: 45%; margin-right: 5%;
                &:nth-child(3n) {margin-right: 5%;}
                &:nth-child(2n) {margin-right: 0;}
            }
        }
        &.our-client {
            h4 {font-size: 18px;}
        }
    }
    


    /* footer
    ----------------------------------------------------------------------------------------------*/


}

@media all and (max-width: 479px) {

    .after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
    .before_clear:before{clear:both;display: block;content:" "}

    .wrapper {}


    /* header
    ----------------------------------------------------------------------------------------------*/
   


    /* homepage
    ----------------------------------------------------------------------------------------------*/
    
    .slideHome {
        .slider {
            .slide {
                .caption {
                    .txt {margin-left: 0px;}
                }
            }
        }
    }
    
    .home_about {
        padding: 200px 0 70px; min-height: 0;
        .img_about {width: 70%;}
    }

    /* middle
    ----------------------------------------------------------------------------------------------*/
    .pg_title {
        .txt_title_pg {width: auto; margin: 0 25px;}
    }
    .next-read {
        .right {
            p {max-width: none;}
        }
    }
    .listing {
        &.about_sub {
            .item {
                padding-bottom: 70px; margin-bottom: 25px;
                .dec {
                    padding: 20px 20px 15px;
                    h5 {margin-bottom: 0;}
                    p {display: none;}
                }
            }
        }
    }
    .slider_market {margin: 25px 10px 0;}

    .content_galery {
        .slider_galery {
            width: auto; max-width: 100%; padding-top: 20px; margin: 0 10px;
            .img {
                height: 160px; width: auto; overflow: hidden;
                img {position: relative;max-width: none; height: 100%; width: 100%; object-fit: cover;}
            }
        }
        .slide {
            overflow: hidden;
            .text_slide {
                padding: 0 50px 0 20px; bottom: 15px;
                .btn {font-size: 0; padding: 0;}
            }
        }
    }
    .form_contact {
        .row {
            .g-recaptcha {@include transform(scale(0.8)); position: relative; left: -2%; width: 304px; margin: 0 auto;}
            &:last-of-type {
                margin-top: 25px;
                .col {float: none; display: block; text-align: center;}
            }
        }
    }

    /* footer
    ----------------------------------------------------------------------------------------------*/


}

@media all and (max-width: 370px) {
    .pg_title {
        .map {
            img {left: -15%;}
        }
    }
}